<template>
  <div>
    <div class="rank" :class="{'rank-height': isShow}">
      <div class="rank__title flex flex__space-between flex__align-center">
        学分排行榜
        <div class="rank__more" @click="toFun">
          更多
          <a-icon type="right" />
        </div>
      </div>
      <div class="rank__list">
        <a-table
          :columns="columns"
          :rowKey="(record) => record.userId"
          :data-source="data"
          :pagination="false"
          size="small"
        >
          <template slot="sequence" slot-scope="record, index, indent">{{indent + 1}}</template>
        </a-table>
      </div>
    </div>
  </div>
</template>
 
<script>
import { scoreTopList } from "@/api";
const columns = [
  {
    title: "序号",
    dataIndex: "sequence",
    key: "sequence",
    align: "center",
    scopedSlots: {
      customRender: "sequence"
    }
  },
  {
    title: "姓名",
    dataIndex: "userRealname",
    key: "userRealname",
    align: "center"
  },
  {
    title: "学分",
    key: "score",
    dataIndex: "score",
    align: "center"
  }
];

export default {
  props: {
    isShow: {
      default: false,
      type: Boolean
    },
    limit: {
      default: 8,
      type: Number
    }
  },
  data() {
    return {
      data: [],
      columns
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      scoreTopList({
        limit: this.limit
      }).then(res => {
        this.data = res.result;
      });
    },
    toFun() {
      this.$router.push({
        name: "credit"
      });
    }
  }
};
</script>
 
<style lang = "less" scoped>
.rank {
  width: 243px;
  height: 261px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  &__title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #c4a468;
    box-sizing: border-box;
    padding: 11px 10px 11px 27px;
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 22px;
      background: #c4a468;
      border-radius: 2px;
    }
  }
  &__more {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
    transition: all 0.4s;
    &:hover {
      color: #376cde;
    }
  }
  &__list {
    box-sizing: border-box;
    padding: 0 10px 10px;
    overflow: hidden;
  }
}
.rank-height {
  height: 405px;
}
</style>