<template>
  <div>
    <div class="recommend">
      <div class="recommend__top flex flex__space-between flex__align-center">
        <div class="recommend__title">
          <a-icon type="star" />每日推荐
        </div>
        <div class="recommend__more" @click="toMore">
          更多
          <a-icon type="right" />
        </div>
      </div>
      <!-- <img @click="toDetail" src="@/assets/images/home/img01.png" alt="" class="recommend__img"> -->
      <div v-if="data">
        <img
          @click="toDetail"
          :src="data.coverImage ? getImageView(data.coverImage) : '@/assets/images/home/img01.png'"
          alt
          class="recommend__img"
        />
        <div @click="toDetail" class="recommend__info">
          <a-tooltip placement="bottom" :title="data.courseName">
            <div class="recommend__info-title">{{data.courseName ? data.courseName : ''}}</div>
          </a-tooltip>
          <div class="recommend__info-row flex flex__align-center flex__space-between">
            <div class="recommend__info-date">{{data.studyDate ? data.studyDate : '2021/7/2'}}</div>
            <div class="recommend__info-number">{{data.studiedUserCount}}人已学习</div>
          </div>
        </div>
      </div>
      <div v-else style="padding-top:30px">
        <a-empty />
      </div>
    </div>
  </div>
</template>

<script>
import { getFileAccessHttpUrl } from "@/utils/tools";
import { recommendToday } from "@/api";

export default {
  data() {
    return {
      data: {}
    };
  },
  created() {
    this.getData();
  },
  computed: {
    getImageView() {
      return avatar => {
        return getFileAccessHttpUrl(avatar);
      };
    }
  },
  methods: {
    toMore() {
      this.$router.push({
        name: "recommend"
      });
    },
    toDetail() {
      this.$router.push({
        name: "gardenDetails",
        query: {
          id: this.data.taskId,
          type: this.data.type
        }
      });
      // this.$router.push({
      //   name: "videoDetails"
      // });
    },
    // 获取今日推荐内容
    getData() {
      recommendToday().then(res => {
        this.data = res.result;
      });
    }
  }
};
</script>

<style lang = "scss" scoped>
.recommend {
  width: 241px;
  height: 246px;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  &__top {
    height: 42px;
    color: #fff;
    /* background: linear-gradient(124deg, #FF9D73, #FE7090); */
    /* background: rgb(58, 114, 196); */
    box-sizing: border-box;
    padding: 0 10px;
    /* background: url("~@/assets/images/home/recommend-bg.png") no-repeat; */
    background-size: 100% 100%;
    background: linear-gradient(124deg, #E10B0B, #D6A97E);
  }
  &__title {
    font-weight: bold;
    font-size: 20px;
  }
  &__more {
    cursor: pointer;
    font-size: 14px;
    transition: all 0.4s;
    &:hover {
      color: #376cde;
    }
  }
  &__img {
    width: 100%;
    height: 140px;
    cursor: pointer;
  }
  &__info {
    box-sizing: border-box;
    padding: 16px 12px;
    cursor: pointer;
    &-title {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #666666;
      margin-bottom: 2px;
      overflow: hidden; //溢出隐藏 */
      text-overflow: ellipsis; //超出显示省略号
      white-space: nowrap; //强制文本在一行内显示
    }
    &-row {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>
