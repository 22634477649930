<template>
  <div :style="{flex:1}">
    <!-- :style="{'background-image':'url('+getFileAccessHttpUrl(taskPathData.mapBg)+') 0% 0% / 100% 100% no-repeat'}" -->
    <div
      class="learn"
      :class="{'learn-img': isShow}"
      :style="{background:'url(' + getFileAccessHttpUrl(taskPathData.mapBg) +') 0% 0% / 100% 100% no-repeat'}"
    >
      <div class="learn__title">{{title}}</div>
      <div
        class="learn__task text__center"
        v-for="(item, index) in taskPathData.taskCompleteRatioList"
        :key="index"
        @click="toTask(item, index)"
        :class="{'learn__task_finish': item.progressStatus === 'FINISH',
        'learn__task_ing': item.progressStatus === 'DOING',
        'learn__task_no': item.progressStatus === 'NOT_START',
        'learn__task_nolock': item.lockFlag === 0,
        'learn__task_active': active === index}"
        :style="{left:item.position ? item.position.x + 'px' : '100px', top:item.position ? item.position.y + 'px' : '100px' }"
      >
        <!-- {{item.taskName}} -->
        <span class="learn__task-title">{{item.taskName}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getFileAccessHttpUrl } from "@/utils/tools.js";
import { getTaskPath } from "@/api";
import Vue from "vue";
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "学习发展"
    }
  },
  computed: {
    getFileAccessHttpUrl() {
      return url => {
        if (!url) {
          return require("@/assets/images/home/no_path.jpg");
        } else {
          return getFileAccessHttpUrl(url);
        }
      };
    }
  },
  data() {
    return {
      taskId: "",
      taskPathData: { mapBg: null, taskPathId: null },
      active: 0
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      // debugger;
      getTaskPath().then(res => {
        // /api/learningTaskPath/current/taskPath/progress
        let lockFlag = 0; // 记录当前任务是否锁定

        console.log(res.result, "res");
        if (res.result === null) {
          console.log(this.taskPathData, "taskPath");
          this.taskPathData.mapBg = null;
        } else {
          this.taskPathData = res.result;
          // 记录下学习路径id
          // debugger;
          Vue.ls.set(
            "TASK_PATH_ID",
            this.taskPathData.taskPathId != ""
              ? this.taskPathData.taskPathId
              : ""
          );
          let arr = this.taskPathData.taskCompleteRatioList;
          let isTask = arr.some((item, index) => {
            this.active = index;
            if (
              item.progressStatus === "NOT_START" ||
              item.progressStatus === "DOING"
            ) {
              // 1 未完成状态时记录下任务的锁定状态
              lockFlag = item.lockFlag;
              // 已解锁的最高级别学习任务, 取这个任务的id展示
              if (
                index + 1 <= arr.length - 1 &&
                item.lockFlag === 0 &&
                arr[index + 1].lockFlag === 1
              )
                return (this.taskId = item.learningTaskId);
            } else {
              // 2 当前任务已完成并且下一个任务为锁定时, 则取当前任务id展示
              if (
                index + 1 <= arr.length - 1 &&
                arr[index + 1].progressStatus === "NOT_START" &&
                arr[index + 1].lockFlag === 1
              )
                return (this.taskId = item.learningTaskId);
            }
          });
          // Vue.ls.set("LOCK_FLAG", lockFlag); // 记录下当前展示的任务是否能够
          if (isTask) {
            // 把符合上文两个条件中其中一个条件的id传出去
            this.$emit("getTaskId", this.taskId);
          } else {
            //上文两个条件都不符合时执行
            if (lockFlag === 1) {
              // 没有完成的任务,并且所有任务都锁定时, 取第一个任务的id展示
              this.$emit("getTaskId", arr[0].learningTaskId);
              return;
            }
            this.active = arr.length - 1;
            // 如果所有任务完成则展示最后一个
            // console.log(arr[arr.length - 1].learningTaskId, 'arr[arr.length - 1].learningTaskId')
            this.$emit("getTaskId", arr[arr.length - 1].learningTaskId);
          }
        }
      });
    },
    // 点击切换任务
    toTask(item, index) {
      if (item.lockFlag === 1) {
        this.$message.warning("抱歉! 该任务还未开启,请联系管理员");
        return false;
      }
      this.$message.success("已切换至当前任务!");
      this.active = index;
      this.$emit("getTaskId", item.learningTaskId);
    }
  }
};
</script>

<style lang = "scss" scoped>
.learn {
  width: 942px;
  height: 523px;
  background: url("~@/assets/images/home/no_path.jpg") 0% 0% / 100% 100%
    no-repeat;
  position: relative;
  &__title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 42px;
    /* background: linear-gradient(90deg, #c4a468 0%, #f6bea5 100%); */
    /* background: rgb(58, 114, 196); */
    box-sizing: border-box;
    padding-left: 30px;
    position: relative;
    width: 120px;
    height: 42px;
    background: linear-gradient(90deg, #E10B0B, #D6A97E);
    border-radius: 0px 10px 10px 0px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      width: 4px;
      height: 22px;
      background: #fff;
    }
  }
  &__box {
    width: 92px;
    height: 30px;
    background: #e2bd7c;
    position: absolute;
  }
}
.learn-img {
  height: 671px;
  /* background: url("~@/assets/images/home/learn-path2.png") no-repeat; */
  background-size: 100% 100%;
}

.learn__task {
  width: 92px;
  height: 31px;
  font-size: 13px;
  background-size: 100% 100%;
  color: #fff;
  font-weight: bolder;
  /* padding: 4px 12px 10px 0px; */
  position: absolute;
  cursor: pointer;
  /* display: inline-block; */
  transform: translate(-50%);
  /* overflow: hidden; //溢出隐藏 */
  /* text-overflow: ellipsis; //超出显示省略号
  white-space: nowrap; //强制文本在一行内显示 */

  &-title {
    padding-top: 2px;
    width: 100px;
    background-size: 100% 100%;
    display: block;
    font-size: 13px;
    position: absolute;
    text-shadow: #1e1e1e 1px 2px 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; //强制文本在一行内显示
    left: -17%;
    transform: translate(11%, -10%);
  }
}

.learn__task_finish {
  background: url("~@/assets/images/home/task_doing-icon.png") 0% 0% / 100% 100%
    no-repeat;
}

.learn__task_ing {
  background: url("~@/assets/images/home/task-icon.png") 0% 0% / 100% 100%
    no-repeat;
}

.learn__task_no {
  background: url("~@/assets/images/home/task-icon.png") 0% 0% / 100% 100%
    no-repeat;
}

.learn__task_nolock {
  /* box-shadow: 0px 0px 3px 3px #bbbbbb; */
  border-bottom: 2px solid #bbbbbb;
}

.learn__task_active {
  /* box-shadow: 0px 0px 3px 3px #fff; */
  border-bottom: 2px solid #fff;
}
</style>
