<template>
 <div >
    <!-- :class="{'learn__height':isImg}" 'background': 'url(' + getFileAccessHttpUrl(bgImage) + ') 0% 0% / 100% 100% no-repeat' -->
   <div class="learn" :style="{'background': 'url(' + getFileAccessHttpUrl(bgImage) + ') 0% 0% / 100% 100% no-repeat'}">
     <div class="learn__title">{{title}}</div>
     <div class="learn__block line_block" v-for="(item, index) in data"  :key="index" :style="{left: item.position.x + 'px', top: item.position.y + 'px'}">
       {{item.taskLevelName}}
       <img v-if="item.progressStatus === 'FINISH'" src="@/assets/images/home/1.png" class="learn__block_img">
       <img v-else-if="item.progressStatus === 'DOING'" src="@/assets/images/home/2.png" class="learn__block_img">
       <img v-else src="@/assets/images/home/3.png" class="learn__block_img">
     </div>
   </div>
 </div>
</template>
 
<script>

import { getFileAccessHttpUrl } from "@/utils/tools";
export default {
  props: {
    bgImage: {
      type: String,
      // default: require('@/assets/images/home/learn-task2.png')
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    title: {
      default: '学习任务',
      type: String
    }
  },
  computed: {
    getFileAccessHttpUrl() {
      return (url) => {
        if (!url) {
          return require('@/assets/images/home/learn-task2.png')
        } else {
          return getFileAccessHttpUrl(url)
        }
      }
    }
  }
}
</script>
 
<style lang = "scss" scoped>
 .learn {
   width: 942px;
   height: 589px;
   /* background: url('~@/assets/images/home/learn-task2.png') no-repeat; */
   /* background-size: 100% 100%; */
   position: relative;
   &__title{
     font-size: 16px;
     font-family: Microsoft YaHei;
     font-weight: bold;
     color: #FFFFFF;
     line-height: 42px;
     /* background: linear-gradient(90deg, #C4A468 0%, #F6BEA5 100%); */
     box-sizing: border-box;
     padding-left: 30px;
     /* position: relative; */
     /* width: 120px; */
     position: absolute;
     padding-right: 10px;
     height: 42px;
     background: linear-gradient(90deg, #E10B0B, #D6A97E);
     border-radius: 0px 10px 10px 0px;
     &::before{
       content: '';
       display: block;
       position: absolute;
       top: 50%;
       left: 15px;
       transform: translateY(-50%);
       width: 4px;
       height: 22px;
       background: #fff;
     }
   }
   &__block{
     position: absolute;
     transform: translate(-50%, -50%);
     color: #666;
     font-weight: bold;
     text-shadow: 0px 0px 3px #ffff;
     &_img {
      display: block;
      margin: 0 auto;
    }
   }
 }

 .learn__height{
   background: url('~@/assets/images/home/learn-task.png') no-repeat;
   background-position-y: 41px;
 }
</style>