<template>
  <div class="home">
    <a-carousel autoplay class="home__banner" :dots="false">
      <div v-for="item of data" :key="item.id">
        <img
          :src="getImageView(item.image)"
          @click="toDetail(item)"
          alt
          v-bind:style="{ width: width +'px', height: height+'px' }"
        />
      </div>
    </a-carousel>
    <div class="container mt flex flex__space-between">
      <learn-path />
      <div>
        <recommend />
        <rank class="mt16" />
      </div>
    </div>
    <div class="container mt mb flex flex__space-between">
      <learn-task />
      <task />
    </div>
  </div>
</template>

<script>
import { getFileAccessHttpUrl } from "@/utils/tools";
import { bannerList } from "@/api/home.js";
import learnPath from "../components/learn-path.vue";
import recommend from "../components/recommend.vue";
import rank from "../components/rank.vue";
import learnTask from "@/components/learn-task";
import task from "@/components/task-list";
export default {
  name: "Home",
  components: {
    learnPath,
    recommend,
    rank,
    learnTask,
    task
  },
  data() {
    return {
      data: [],
      width: 1920,
      height: 350
    };
  },
  created() {
    this.getData();
  },
  computed: {
    getImageView() {
      return avatar => {
        return getFileAccessHttpUrl(avatar);
      };
    }
  },
  methods: {
    getData() {
      const paramter = {};
      Object.assign(paramter);
      bannerList({
        forwardType: "PC"
      }).then(res => {
        this.data = res.result.records;
      });
    },
    toDetail(item) {
      this.$router.push({
        name: "videoDetails",
        query: { id: item.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.home__banner {
  width: 100%;
}
.mt {
  margin-top: 17px;
}
.mb {
  margin-bottom: 33px;
}
.mt16 {
  margin-top: 16px;
}
</style>
